import React from 'react'
import Layout from 'components/misc/Layout'

const NotFoundPage = () => (
  <Layout>
    <h1>Página no encontrada</h1>
    <p>Lo sentimos, no se ha encontrado la página solicitada.</p>
  </Layout>
)

export default NotFoundPage
